import React, { useEffect, useState } from "react"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { navigate } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Section, Container } from "../../components/grid/grid"
// import bgPage from "../../assets/img/double-lucky-draw-bg.png"
import bgPageDesktop from "../../assets/lucky/desktop/form.jpg"
import bgPageMobile from "../../assets/lucky/mobile/form.jpg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_PHONENUMBER = gql`
  query formDoubleLuckyDraw {
    formDoubleLuckyDraw {
      project
      phoneNumber
    }
  }
`

const LuckyDipPage = location => {
  const [getResult, { data }] = useLazyQuery(GET_PHONENUMBER)
  const resizeScreen = useMediaPredicate("(max-width: 800px)")
  const ogUrl = `${process.env.BASE_URL}${location.path ? location.path : ""}`
  const bgPage = resizeScreen ? bgPageMobile : bgPageDesktop

  let listOfProduct = [
    { label: "The Loop - BSD", value: "The Loop - BSD" },
    {
      label: "Delrey Business Townhouse - BSD",
      value: "Delrey Business Townhouse - BSD",
    },
    { label: "Ruko Taman Tekno X - BSD", value: "Ruko Taman Tekno X - BSD" },
    {
      label: "Kavling Taman Tekno X - BSD",
      value: "Kavling Taman Tekno X - BSD",
    },
    { label: "Alfiore - Tangerang", value: "Alfiore - Tangerang" },
    {
      label: "KAvling Cloverville - Surabaya",
      value: "KAvling Cloverville - Surabaya",
    },
    { label: "Kios Pasar Modern - BSD", value: "Kios Pasar Modern - BSD" },
    { label: "Cheville - Kalimantan", value: "Cheville - Kalimantan" },
    { label: "Ruko Madrid X - BSD", value: "Ruko Madrid X - BSD" },
    { label: "MYZA - BSD", value: "MYZA - BSD" },
    { label: "Atherton - Kota Wisata", value: "Atherton - Kota Wisata" },
    {
      label: "Southgate Prime - Jakarta",
      value: "Southgate Prime - Jakarta",
    },
    { label: "Cluster Askara Nue - BSD", value: "Cluster Askara Nue - BSD" },
    { label: "The Blizfield - BSD", value: "The Blizfield - BSD" },
    {
      label: "Latinos Business Dis Thp 3 - BSD",
      value: "Latinos Business Dis Thp 3 - BSD",
    },
    { label: "Tanakayu Jiva - BSD", value: "Tanakayu Jiva - BSD" },
    { label: "Tanakayu Svani - BSD", value: "Tanakayu Svani - BSD" },
    { label: "Tanakayu Svadhi - BSD", value: "Tanakayu Svadhi - BSD" },
    { label: "Tanakayu Svasti - BSD", value: "Tanakayu Svasti - BSD" },
    { label: "Tanakayu Chava - BSD", value: "Tanakayu Chava - BSD" },
    { label: "Aerra by Eonna - BSD", value: "Aerra by Eonna - BSD" },
    { label: "Grand Freja - BSD", value: "Grand Freja - BSD" },
    { label: "IMAJIHAUS - BSD", value: "IMAJIHAUS - BSD" },
    { label: "Impresahaus - BSD", value: "Impresahaus - BSD" },
    { label: "Freja Suites - BSD", value: "Freja Suites - BSD" },
    { label: "Nordville - Kalimantan", value: "Nordville - Kalimantan" },
    {
      label: "New Palladium - Kalimantan",
      value: "New Palladium - Kalimantan",
    },
    { label: "The Savia Thp 1 - BSD", value: "The Savia Thp 1 - BSD" },
    {
      label: "Visana at The Savia - BSD",
      value: "Visana at The Savia - BSD",
    },
    {
      label: "Cluster Whitsand II - BSD",
      value: "Cluster Whitsand II - BSD",
    },
    { label: "Cluster Whelford - BSD", value: "Cluster Whelford - BSD" },
    { label: "Cluster Caelus - BSD", value: "Cluster Caelus - BSD" },
    {
      label: "Cluster Azura Starlight - BSD",
      value: "Cluster Azura Starlight - BSD",
    },
    {
      label: "Landed Phase 2 (Blok EFGH) - Batam",
      value: "Landed Phase 2 (Blok EFGH) - Batam",
    },
    {
      label: "Landed Phase 1 (Blok ABCD) - Batam",
      value: "Landed Phase 1 (Blok ABCD) - Batam",
    },
    { label: "Rumah Zena - BSD", value: "Rumah Zena - BSD" },
    { label: "Aure - BSD", value: "Aure - BSD" },
    {
      label: "The Nove Kavling Phase 2 - Batam",
      value: "The Nove Kavling Phase 2 - Batam",
    },
    { label: "Grand Palais - Surabaya", value: "Grand Palais - Surabaya" },
    { label: "Garden Suite - Surabaya", value: "Garden Suite - Surabaya" },
    {
      label: "London Park Avenue Thp 2 - Surabaya",
      value: "London Park Avenue Thp 2 - Surabaya",
    },
    {
      label: "The Nove Landed Phase 1 (Blok ABCD) - Batam",
      value: "The Nove Landed Phase 1 (Blok ABCD) - Batam",
    },
    { label: "Grand Palais - Surabaya", value: "Grand Palais - Surabaya" },
    { label: "Garden Suite - Surabaya", value: "Garden Suite - Surabaya" },
    {
      label: "London Park Avenue Thp 2 - Surabaya",
      value: "London Park Avenue Thp 2 - Surabaya",
    },
    {
      label: "The Nove Landed Phase 1 (Blok ABCD) - Batam",
      value: "The Nove Landed Phase 1 (Blok ABCD) - Batam",
    },
    { label: "Autoparts - BSD", value: "Autoparts - BSD" },
    {
      label: "Kios Pasar Modern 2/ Barat - BSD",
      value: "Kios Pasar Modern 2/ Barat - BSD",
    },
    {
      label: "Casa De Parco Magnolia - BSD",
      value: "Casa De Parco Magnolia - BSD",
    },
    {
      label: "Casa De Parco Gardenia - BSD",
      value: "Casa De Parco Gardenia - BSD",
    },
    {
      label: "Casa De Parco Cassea - BSD",
      value: "Casa De Parco Cassea - BSD",
    },
    { label: "Alegria Park - BSD", value: "Alegria Park - BSD" },
    { label: "Cluster Alesha - BSD", value: "Cluster Alesha - BSD" },
    { label: "Cluster Asatti - BSD", value: "Cluster Asatti - BSD" },
    { label: "Alexandria - Surabaya", value: "Alexandria - Surabaya" },
    {
      label: "Street of London - Surabaya",
      value: "Street of London - Surabaya",
    },
    {
      label: "Street of Paris - Surabaya",
      value: "Street of Paris - Surabaya",
    },
    {
      label: "Palais de Luxembourg - Surabaya",
      value: "Palais de Luxembourg - Surabaya",
    },
    { label: "Grand Palais - Surabaya", value: "Grand Palais - Surabaya" },
    { label: "Kavling Phase 1 - Batam", value: "Kavling Phase 1 - Batam" },
    { label: "AMARINE - BSD", value: "AMARINE - BSD" },
    { label: "Amata - BSD", value: "Amata - BSD" },
    { label: "Blossom Ville Ex - BSD", value: "Blossom Ville Ex - BSD" },
    { label: "Royal Blossom - BSD", value: "Royal Blossom - BSD" },
    { label: "Giri Loka - BSD", value: "Giri Loka - BSD" },
    { label: "Ingenia - BSD", value: "Ingenia - BSD" },
    { label: "Precia - BSD", value: "Precia - BSD" },
    { label: "VIVACIA - BSD", value: "VIVACIA - BSD" },
    { label: "Anila House - BSD", value: "Anila House - BSD" },
    { label: "Assana House - BSD", value: "Assana House - BSD" },
    { label: "Azura House - BSD", value: "Azura House - BSD" },
    { label: "DE MAJA - BSD", value: "DE MAJA - BSD" },
    { label: "HYLANDS - BSD", value: "HYLANDS - BSD" },
    { label: "LUXMORE - BSD", value: "LUXMORE - BSD" },
    { label: "Cattleya - BSD", value: "Cattleya - BSD" },
    {
      label: "Plot A, Taman Banjar Wijaya - Tangerang",
      value: "Plot A, Taman Banjar Wijaya - Tangerang",
    },
    {
      label: "Da Vinci - Legenda Wisata",
      value: "Da Vinci - Legenda Wisata",
    },
    {
      label: "Element Tower 1 - Jakarta",
      value: "Element Tower 1 - Jakarta",
    },
    {
      label: "Element Tower 2 - Jakarta",
      value: "Element Tower 2 - Jakarta",
    },
    {
      label: "Southgate Elegance - Jakarta",
      value: "Southgate Elegance - Jakarta",
    },
    {
      label: "Southgate Altuerra - Jakarta",
      value: "Southgate Altuerra - Jakarta",
    },
    { label: "Tower Azure - Surabaya", value: "Tower Azure - Surabaya" },
    { label: "Tower Kalani - Batam", value: "Tower Kalani - Batam" },
    { label: "Tower Kaina - Batam", value: "Tower Kaina - Batam" },
    { label: "Aerium - Jakarta", value: "Aerium - Jakarta" },
    { label: "Cluster Assana - BSD", value: "Cluster Assana - BSD" },
    { label: "Albera - BSD", value: "Albera - BSD" },
    { label: "Tanakayu Vasya - BSD", value: "Tanakayu Vasya - BSD" },
    { label: "Nusa Loka - BSD", value: "Nusa Loka - BSD" },
    { label: "New Alesha - BSD", value: "New Alesha - BSD" },
    { label: "Vanya Avenue - BSD", value: "Vanya Avenue - BSD" },
    { label: "Excelia - Tangerang", value: "Excelia - Tangerang" },
    { label: "Virgina Arcade - BSD", value: "Virgina Arcade - BSD" },
  ]

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const phoneNumberHandler = (val, setFieldValue) =>
      setFieldValue("phoneNumber", val)
    const [checked, setChecked] = useState(false)

    function handleOnChange() {
      setChecked(!checked)
    }

    return (
      <Form className="row pt-4" id="surpriseForm">
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama Customer</label>
          <Field
            name="name"
            className="form-control px-0"
            placeholder="Nama Anda"
            type="text"
            id="customerName"
          />
          {touched.name && errors.name && (
            <div className="invalid-value d-block">{errors.name}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nomor Handphone Customer</label>
          <NumberFormat
            name="phoneNumber"
            className="form-control px-0"
            placeholder="Nomor Handphone"
            allowEmptyFormatting
            prefix="+62"
            value={values.phoneNumber}
            onValueChange={val =>
              phoneNumberHandler(val.formattedValue, setFieldValue)
            }
            id="customerPhone"
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <div className="invalid-value d-block">{errors.phoneNumber}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Email</label>
          <Field
            name="email"
            className="form-control px-0"
            placeholder="Email Anda"
            type="text"
            id="customerEmail"
          />
          {touched.email && errors.email && (
            <div className="invalid-value d-block">{errors.email}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Produk Yang Dibeli</label>
          <Select
            className="form-control px-0"
            classNamePrefix="Produk Yang Dibeli"
            options={listOfProduct}
            defaultValue={{ value: "", label: "Produk Yang Dibeli" }}
            id="product"
            onChange={e => {
              setFieldValue("product", e.value)
              sessionStorage.setItem("product", e.value)
            }}
          />
          {touched.product && errors.product && (
            <div className="invalid-value d-block">{errors.product}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">
            Nomor Blok/Lantai (Note: Input tanpa spasi)
          </label>
          <Field
            name="blok"
            className="form-control px-0"
            placeholder="Contoh: B1 atau 1B"
            type="text"
            id="blok"
          />
          {touched.blok && errors.blok && (
            <div className="invalid-value d-block">{errors.blok}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">
            Nomor Unit (Note: Input tanpa spasi)
          </label>
          <Field
            name="unit"
            className="form-control px-0"
            placeholder="Contoh: A52 atau 52A"
            type="text"
            id="unit"
          />
          {touched.unit && errors.unit && (
            <div className="invalid-value d-block">{errors.unit}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama Sales</label>
          <Field
            name="salesName"
            className="form-control px-0"
            placeholder="Nama sales"
            type="text"
            id="salesName"
          />
          {touched.salesName && errors.salesName && (
            <div className="invalid-value d-block">{errors.salesName}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">
            <Field
              type="checkbox"
              name="checked"
              value="Agent"
              onClick={handleOnChange}
            />
            &nbsp;Agent
          </label>
        </div>
        <div
          className={
            checked
              ? "col-12 col-md-12 pb-3 d-block"
              : "col-12 col-md-12 pb-3 d-none"
          }
        >
          <label className="text-muted my-0">Nama Kantor</label>
          <Field
            name="officeName"
            className="form-control px-0"
            placeholder="Nama Kantor"
            type="text"
            id="officeName"
          />
          {touched.officeName && errors.officeName && (
            <div className="invalid-value d-block">{errors.officeName}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3 pt-3" id="disclaimer">
          <label className="text-muted my-0">
            <Field
              type="checkbox"
              name="isAgree"
              value="isAgree"
              id="isAgree"
            />
            &nbsp;Saya menyetujui bahwa hasil dari Double Lucky Draw ini tidak
            dapat diganggu gugat
          </label>
          {touched.isAgree && errors.isAgree && (
            <div className="invalid-value d-block">{errors.isAgree}</div>
          )}
        </div>
        <div className="col-12 pt-5">
          <button className="btn btn-primary w-100 w-md-25">
            {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </div>
      </Form>
    )
  }

  useEffect(() => {
    if (location.uri == "/double-luckydraw") {
      let navbarDesktop = document
        .getElementById("desktopNav")
        .querySelectorAll(".nav-link")

      if (resizeScreen) {
        document.getElementsByClassName(
          "logo-secondary"
        )[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      } else {
        document.getElementsByClassName(
          "logo-secondary"
        )[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
      document.querySelector("#lang-wrapper").classList.remove("d-flex")
      document.querySelector("#lang-wrapper").classList.add("d-none")
    }
  }, [])

  useEffect(() => {
    getResult()
  }, [])

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    product: "",
    blok: "",
    unit: "",
    salesName: "",
    isAgree: "",
    officeName: "",
  })

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({
      name,
      phoneNumber,
      email,
      product,
      blok,
      unit,
      salesName,
      isAgree,
      officeName,
    }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        product: formValues.product || product,
        blok: formValues.blok || blok,
        unit: formValues.unit || unit,
        salesName: formValues.salesName || salesName,
        isAgree: formValues.isAgree || isAgree,
        officeName: formValues.officeName || officeName,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      product: Yup.string().required("Produk Yang Dibeli is required"),
      blok: Yup.string().required("No blok is required"),
      unit: Yup.string().required("No unit is required"),
      salesName: Yup.string().required("Nama sales is required"),
      isAgree: Yup.string().required("Disclaimer is required"),
    }),
    handleSubmit(values) {
      // setFormValues(values)
      // let dataList = data.formDoubleLuckyDraw || []
      // for (let i = 0; i < dataList.length; i++) {
      //   if (dataList[i].phoneNumber == values.phoneNumber.split("+").join("")) {
      //     alert("Maaf! Anda tidak bisa mengikuti program Lucky Dip kembali")
      //     return false
      //   }
      // }
      // let form = document.getElementById("surpriseForm")
      // for (let q = 0; q < form.elements.length; q++) {
      //   let eSecond = form.elements[q]
      //   if (eSecond.type === "button") continue
      //   let idSecond = eSecond.id
      //   var valueSecond = eSecond.value
      //   sessionStorage.setItem(idSecond, valueSecond)
      // }
      // navigate("/double-luckydraw/pop")
    },
  })(LeadForm)

  return (
    <Layout location={location} currentLocation={location.uri}>
      <SEO
        title={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        fbTitle={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        twitterTitle={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        description={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        fbDescription={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        twitterDescription={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        url={ogUrl}
        img={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
        fbImg={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
        twitterImg={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
      />
      <Helmet>
        <meta name="Permissions-Policy" content="autoplay=(self)"></meta>
        <body className="bd-page" />
        <meta
          name="keywords"
          content="Double Lucky Draw, diskon properti sinar mas land, program double lucky draw, "
        ></meta>
        <meta name="copyright" content="2025 | Sinar Mas Land"></meta>
      </Helmet>
      <Section
        className="mainSurpriseKetupat bg-lucky-dip-form bg-gray-50"
        id="extraKetupatSection"
        style={{
          backgroundImage: `url(${bgPage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container className="surpriseForm">
          <h1
            className="text-center"
            style={{
              color: "#1e333e",
            }}
          >
            Double Lucky Draw
          </h1>
          <EnhancedLeadForm />
        </Container>
      </Section>
    </Layout>
  )
}
export default LuckyDipPage
